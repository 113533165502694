// @flow
import React from 'react'

import { linkToAppSignup, linkToAppLogin, linkToAppJoinClass } from 'src/links'

import { useTranslateObject } from 'src/intl'

import NavbarWrapper from './components/NavbarWrapper'
import NavbarLink from './components/NavbarLink'
import styles from './Navbar.module.scss'

const strings = {
  signUp: 'common.navbar.signUp',
  logIn: 'common.navbar.logIn',
  joinClass: 'common.navbar.joinClass',
}

type Props = {|
  pagePath: ?string,
|}

export default function Navbar({ pagePath }: Props) {
  const messages = useTranslateObject(strings)

  return (
    <NavbarWrapper pagePath={pagePath}>
      <NavbarLink
        external
        text={messages.joinClass}
        link={linkToAppJoinClass()}
        color="black"
      />
      <NavbarLink
        external
        text={messages.logIn}
        link={linkToAppLogin()}
        color="black"
      />
      <NavbarLink
        external
        text={messages.signUp}
        color="transparent"
        link={linkToAppSignup()}
        className={styles.button}
      />
    </NavbarWrapper>
  )
}
