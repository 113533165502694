// @flow
import React, { type Node, useState } from 'react'

import SenecaLogo from 'src/common/components/Logos/components/SenecaLogo'
import LocaleModal from 'src/components/LocaleModal'

import NavbarDesktop from '../NavbarDesktop'
import NavbarMobile from '../NavbarMobile'
import NavbarLocale from '../NavbarLocale'
import styles from './NavbarWrapper.module.scss'

type Props = {|
  children?: Node,
  pagePath: ?string,
|}

export default function NavbarWrapper({ children, pagePath }: Props) {
  const [showModal, setShowModal] = useState(false)

  const handleModalHide = () => {
    setShowModal(false)
  }

  const handleModalShow = () => {
    setShowModal(true)
  }

  return (
    <>
      <div className={styles.wrapper}>
        <SenecaLogo />
        {children && (
          <>
            <NavbarDesktop>{children}</NavbarDesktop>
            <NavbarLocale onClick={handleModalShow} />
            <NavbarMobile>{children}</NavbarMobile>
          </>
        )}
      </div>
      <LocaleModal
        onExit={handleModalHide}
        show={showModal}
        pagePath={pagePath}
      />
    </>
  )
}
