// @flow

import React from 'react'
import classnames from 'classnames'

import styles from './NavbarLink.module.scss'
import {
  SenecaLink,
  SenecaLinkExternal,
} from '../../../../../components/SenecaLink/SenecaLink'

type Props = {|
  link: string,
  openInNewTab?: boolean,
  text: string,
  external?: boolean,
  color?: 'black',
|}

export default function NavbarLink({
  link,
  openInNewTab,
  text,
  external,
  color,
}: Props) {
  if (external)
    return (
      <SenecaLinkExternal
        link={link}
        openInNewTab={openInNewTab}
        className={classnames(styles.wrapper, {
          [styles.black]: color === 'black',
        })}
      >
        {text}
      </SenecaLinkExternal>
    )
  else
    return (
      <SenecaLink
        to={link}
        className={classnames(styles.wrapper, { [styles[color]]: color })}
      >
        {text}
      </SenecaLink>
    )
}
