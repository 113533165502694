// @flow
import React from 'react'
import { useIntl } from 'react-intl'

import {
  linkToAppSignup,
  linkToAppLogin,
  linkToTermsPage,
  linkToPrivacyPage,
  linkToCookiesPage,
} from 'src/links'

import { ButtonLink } from 'src/common/components/Button'

import { useTranslateObject } from 'src/intl'

import NavbarWrapper from './components/NavbarWrapper'
import NavbarLink from './components/NavbarLink'
import styles from './Navbar.module.scss'

const strings = {
  signUp: 'common.navbar.signUp',
  logIn: 'common.navbar.logIn',
  terms: 'common.navbar.terms',
  cookies: 'common.navbar.cookies',
  privacy: 'common.navbar.privacy',
}

export default function NavbarLegal() {
  const messages = useTranslateObject(strings)
  const { locale } = useIntl()

  return (
    <NavbarWrapper>
      <NavbarLink text={messages.terms} link={linkToTermsPage(locale)} />
      <NavbarLink text={messages.privacy} link={linkToPrivacyPage(locale)} />
      <NavbarLink text={messages.cookies} link={linkToCookiesPage(locale)} />
      <NavbarLink external text={messages.logIn} link={linkToAppLogin()} />
      <ButtonLink
        external={true}
        text={messages.signUp}
        color="blue"
        linkExternal={linkToAppSignup()}
        className={styles.button}
      />
    </NavbarWrapper>
  )
}
